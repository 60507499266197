<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('BasePhoneMatchingCreate')"
    @edit="(id) => onEdit('BasePhoneMatchingEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'BasePhoneMatching',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'BasePhones',
      tableCaption: 'Соответствие баз и телефонов для роботов',
      breadcrumbs: [
        {
          text: 'Колл-центр',
          route: { name: 'CallCenterMain' },
        },
        {
          text: 'Соответствие баз и телефонов для роботов',
        },
      ],
      tableHeaders: [
        {
          text: 'Номер телефона',
          alias: 'phoneNumber',
          order: 'phoneNumber',
        },
        {
          text: 'Название базы данных',
          alias: 'databaseName',
          order: 'databaseName',
        },
        {
          text: 'Количество параллельных линий',
          alias: 'parallelLineCount',
          order: 'parallelLineCount',
        },
        {
          text: 'Комментарий',
          alias: 'comment',
          order: 'comment',
        },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
